import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import StyledLink from '../components/styles/StyledLink'
import SEO from '../components/SEO'

const Container = styled.div`
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 120px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const TopHeading = styled.h1`
  font-size: 38px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 60px;
  line-height: 1.2;
`

const Bold = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
`

const Impressum = () => {
  return (
    <>
      <SEO title="Impressum" />
      <Container>
        {/* <HeadingMain mainHeading="Impressum" /> */}
        <TopHeading>Impressum</TopHeading>
        Für den Inhalt verantwortlich:
        <br />
        <br />
        <Bold>Weingut Göschl GesbR</Bold>
        {/* <br /> */}
        Am Kanal 4<br />
        7122 Gols
        <br />
        <br />
        E-Mail:{' '}
        <StyledLink as="a" href="mailto:office@weingut-goeschl.at">
          office@weingut-goeschl.at
        </StyledLink>
        <br />
        Tel:{' '}
        <StyledLink as="a" href="tel:+436644530988">
          +43 664 4530988
        </StyledLink>
        <br />
        Web:{' '}
        <Link to="/">
          <StyledLink>www.weingut-goeschl.at</StyledLink>
        </Link>
        <br />
        <br />
        Unternehmensgegenstand: Weinproduktion und -verkauf
        <br />
        UID-Nr: ATU65671524
        <br />
        <br />
        Fotos:
        <br />
        Stefan Heider
        <br />
        <br />
        Web Design & Implementierung:
        <br />
        <StyledLink as="a" target="_blank" rel="noopener noreferrer" href="https://www.peterkroyer.at">
          www.peterkroyer.at
        </StyledLink>
      </Container>
    </>
  )
}

export default Impressum
